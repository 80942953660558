const $ = require('jquery-slim')

const detach = wrapper => {
  wrapper
    .detach()
    .addClass('notification-wrapper')
    .prependTo('html')
}

const removeByTime = notifier => {
  setTimeout(t => {
    notifier.remove()
  }, 3000)
}

const removeByClick = notifier => {
  notifier.on('click', e => {
    notifier.remove()
  })
}

const createNotification = (message, classes) => {
  const wrapper = $('[notification-wrapper]')

  const notifier = $('<div> </div>')
  notifier.addClass('notification ' + classes)
  notifier.text(message)
  notifier.appendTo(wrapper)

  removeByTime(notifier)
  removeByClick(notifier)

  return notifier
}

const startNotification = function () {
  const wrapper = $('[notification-wrapper]')
  detach(wrapper)
}

export {startNotification, createNotification}
