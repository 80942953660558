import './style.scss'
import $ from 'jquery-slim'
import { activeTop } from './components/header/header.js'
import { startContact } from './components/contact/contact.js'
import SmoothScroll from 'smooth-scroll'

activeTop()
startContact()

const scroll = new SmoothScroll('a[href*="#"]', {
	easing: 'easeInOutCubic'
})

$(window).on('scroll', scroll => {
  activeTop()
})

console.log('HelpEduc')
