import axios from 'axios'
import $ from 'jquery-slim'
import {startNotification, createNotification} from '../notification/notification.js'

startNotification()

const EMAIL_END_POINT = 'https://incubatech-email.herokuapp.com/email'

const inputs = {
  name: $('#contact_name'),
  email: $('#contact_email'),
  comment: $('#contact_comment'),
  submit: $('#contact_submit')
}

const errors = {
  name: $('.error__name'),
  email: $('.error__email'),
  comment: $('.error__comment'),
  clear: () => { $('.form__error') }
}

const loader = $('#loading')

const isEmail = email => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

const checkInput = (value, error) => {
  if (!value) {
    error.css({display: 'block'})
  }else{
    error.css({display: 'none'})
  }

  return value
}

const checkInputsOnChange = () => {
  inputs.name.on('change', event => {
    checkInput(event.target.value, errors.name)
  })

  inputs.email.on('change', event => {
    checkInput(isEmail(event.target.value), errors.email)
  })

  inputs.comment.on('change', event => {
    checkInput(event.target.value, errors.comment)
  })
}

const sendEmail = () => {
  axios({
    method: "post",
    url: EMAIL_END_POINT,
    headers: {
      'token': 'oziO848qGKwtyoexU1oqjAzgBoykkhC8',
      'accept': 'application/json'
    },
    data: {
      'provider': 'incubatech',
      'name': 'Contato: helpeduc.com.br',
      'emailfrom': `${inputs.email.val()}`,
      'emailto': 'helpeduc@roboeduc.com',
      'subject': `Email enviado por - ${inputs.name.val()}: ${inputs.email.val()}`,
      'content': `${inputs.comment.val()}`
    }
  })
  .then(res => {
    loader.css({'display': 'none'})
    clearAllValues()
    createNotification('Email enviado!', 'notification-success')
  })
  .catch(console.log)
}

const clearAllValues = () => {
  inputs.name.val('')
  inputs.email.val('')
  inputs.comment.val('')
}

const checkAllInputs = () => {
  const name = checkInput(inputs.name.val(), errors.name)
  const email = checkInput(isEmail(inputs.email.val()), errors.email)
  const comment = checkInput(inputs.comment.val(), errors.comment)

  return name && email && comment
}

const emailHandler = () => {
  if (checkAllInputs()) {
    loader.css({'display': 'flex'})
    sendEmail()
  }
}

const startContact = () => {
  checkInputsOnChange()
  inputs.submit.click(emailHandler)
}

export {
  startContact
}
