import $ from 'jquery-slim'

const activeTop = () => {
  const scrollPosition = $(window).scrollTop()

  if (scrollPosition > 0) {
    $('.menu').addClass('menu--active')
  }else{
    $('.menu').removeClass('menu--active')
  }
}

export {
  activeTop
}
